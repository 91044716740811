.locationinfo {
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    padding: 20px;    
}

.locationarrow {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}