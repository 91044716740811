
.lockpick-container {
    text-align: center;
    font-family: Arial, sans-serif;
    width: 400px;    
}

.lockpick-canvas {
    position: relative;
    width: 400px;
    height: 300px;
    overflow: hidden;
}

.lock {
    position: relative;
    top: 0;
    left: 0;    
    width: 500px;
    height: 300px;
    overflow: hidden;
}

.pins {
    position: relative;
    width: 500px;
    height: 300px;
    top: -300px;
}

.pin {    
    position: relative;
    left: 0;
    top: 0;
    width: 500px;
    height: 300px;
    margin: 0 0;
    cursor: pointer;
    transition: transform 0.2s;
}

.pin img {    
    width: 100%;
    height: 100%;
}

.pin.active {
    transform: translateY(-40px);
}

.unlocked-message {
    margin-top: 20px;
    font-size: 24px;
    color: green;
}
