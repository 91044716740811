.minibutton {    
    border-radius: 3px;
    color: #333;
    cursor: pointer;
    display: inline;
    font-size: 12px;
    font-weight: bold;
    margin: 0 5px;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}