.gamecard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
    height: 100%;
    border-radius: 10px;
    background-color: #1d1d1d;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin: 10px;
}

.gametitle {
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    font-weight: 700;
    color: #fff;
    padding: 10px;
    background-color: #1d1d1d;
}

.gamedescription {
    display: flex;
    flex-direction: column;
    font-size: 1.1rem;
    color: #fff;
    background-color: #1d1d1d;
}

.gameattributes {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 1rem;
    color: #fff;
    padding: 10px;
    background-color: #1d1d1d;
}