.fixed {
    font-family: 'Courier New', Courier, monospace;
    align-items: start;
    text-align: start;
    font-weight: bold;
    font-size: 1.5em;
}

.correct {
    color: #00FF00;
}

.incorrect {
    color: #FF0000;
}

.inputcipher {
    font-family: 'Courier New', Courier, monospace;
    align-items: start;
    text-align: start;
    font-weight: bold;
}