.jigsawpuzzle {
    margin-bottom: 30px;
}

.jigsaw {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 1px;
    width: 300px;
    height: 300px;
}

.jigsaw div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    color: white;
    background-color: #333;
}

.jigsawpiece1 {
    background-image: url('cryptic.jpg');
    background-size: 300px;
}

.jigsawpiece2 {
    background-image: url('cryptic.jpg');
    background-size: 300px;
    background-position-x: 200px;
}

.jigsawpiece3 {
    background-image: url('cryptic.jpg');
    background-size: 300px;
    background-position-x: 400px;
}

.jigsawpiece4 {
    background-image: url('cryptic.jpg');
    background-size: 300px;
    background-position-y: 200px;
}

.jigsawpiece5 {
    background-image: url('cryptic.jpg');
    background-size: 300px;
    background-position-x: 200px;
    background-position-y: 200px;
}

.jigsawpiece6 {
    background-image: url('cryptic.jpg');
    background-size: 300px;
    background-position-x: 400px;
    background-position-y: 200px;
}

.jigsawpiece7 {
    background-image: url('cryptic.jpg');
    background-size: 300px;
    background-position-y: 400px;
}

.jigsawpiece8 {
    background-image: url('cryptic.jpg');
    background-size: 300px;
    background-position-x: 200px;
    background-position-y: 400px;
}

.jigsawpiece9 {
    background-image: url('cryptic.jpg');
    background-size: 300px;
    background-position-x: 400px;
    background-position-y: 400px;
}


#forPuzzle {
    position: relative;
    width: 320px;
    height: 400px;
    top: 0;
    left: 0;
    overflow: hidden;
    background-color: rgb(59, 59, 59);;
  }

  .polypiece {
    display: block;
    overflow: hidden;
    position: absolute;
  }

  .moving {
    transition-property: top, left;
    transition-duration: 1s;
    transition-timing-function: linear;
  }

  .gameCanvas {
    display: none;
    overflow: hidden;
    position: relative;
    background-color: red;;
  }
