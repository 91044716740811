:root {
  --primary-color: #282c34;
  --primary-text-color: white;
  --primary-color-highlight: #383c44;
  --primary-color-lighter: #484c54;
  --secondary-color: #61dafb;
  --secondary-color-disabled: #7a7a7a;
  --tertiary-color: #00ff9d;
  --tertiary-text-color: #282c34;
  --nav-active: #096cc9;
  --bg-color: white;
  --light-color: white;

  --font-size: 16px;

  --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  --font-family-code: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;

  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-weight-black: 900;
}

#root {
  font-family: var(--font-family);
  font-size: var(--font-size);
  background-color: var(--primary-color);
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

body {
  color: var(--primary-text-color);
  background-color: var(--primary-color);
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
  color: white;
}

label {
  padding: 5px;
}

.App {
  text-align: center;
  max-width: 500px;
  background-color: var(--primary-color-highlight);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Small-header {
  background-color: #282c34;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(1px + 2vmin);
  color: white;
}

.imgplaceholder {
  background-color: var(--primary-color-highlight);
  min-height: 200px;
  pointer-events: none;
}

.gridcontainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
}

.vcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--primary-text-color);
  width: 100%;
}

.hcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  color: var(--primary-text-color)
}

.gamecontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.helpcontainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 50%;
  margin: auto;
  margin-top: 10px;
  padding: 10px;
}

.ticketcontainer {
  display: flex;
  flex-direction: column;  
  align-items: start;
  font-size: 1.5em;
  margin-top: 20px;
}

.hitem{ 
  background-color: var(--primary-color-lighter);
  padding-left: 5px;
  padding-right: 5px;
}

.gridrow {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px;
  width: 100%;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

h1 {
  line-height: 1em;
  margin: 0;
}

h2 {
  line-height: 1em;
  margin: 0;
}

h3 {
  line-height: 1em;
  margin: 0;
}

.checklist {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  padding: 15px;
  text-align: start;
  margin: 10px;
}

.checklist h1 {
  text-align: center;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.list p {
  line-height: 1em;
  margin: 0;
}

li {
  list-style-type: square;
}

.NavLink {
  color: var(--tertiary-text-color);
  text-decoration: none;
}

.Navlink.active {
  color: var(--tertiary-color);
}

a.active {
  color: var(--primary-text-color);
}

.container {
  color: var(--light-color);
  width: 100%;
}

p {
  margin: 0;
}

ul {
  text-align: start;
}

.button {
  display: flex;
  justify-content: space-between;
  flex: 1;
  background-color: var(--tertiary-color);
  /* Green */
  border: none;
  color: var(--tertiary-text-color);
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
  margin: 4px 2px;
  cursor: pointer;
  min-width: 200px;
  height: 30px;
  gap: 5px;
  border-radius: 10px;
}

.navButton {
  background-color: var(--secondary-color);
  /* Green */
  border: none;
  color: var(--primary-text-color);
  padding: 10px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  margin: 4px 2px;
  cursor: pointer;
  min-width: 30px;
  height: 30px;
}

.navButton.disabled {
  background-color: var(--secondary-color-disabled);
}

.menu {
  padding: 10px;
  background-color: var(--bg-color);
  color: var(--primary-color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  width: 100%;
  top: 0;
}

.menu a {
  color: var(--primary-color);
  font-variant: small-caps;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  gap: 20px;
}

.menu a.active {
  color: var(--nav-active);
}

.menu .logo {
  display: flex;
  font-size: 20px;
  font-weight: bold;
}

.menu .help {
  display: flex;
  font-size: 30px;
  font-weight: bold;
  margin-left: auto;
  gap: 10px;
}

.menubutton {
  margin: 5px;
  cursor: pointer;
}

li a {
  color: var(--primary-text-color);
  text-decoration: none;
}

.spacer {}

input {
  padding: 10px;
  margin: 10px;
  font-size: 20px;
}

.paragraph {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 20px;
  font-weight: var(--font-weight-light);
  text-align: start;
  align-items: start;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
}

.formgroup {
  padding: 10px;
  font-size: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  background-color: var(--primary-color-lighter);
  width: 100%;
}

.formlabel {
  padding: 10px;  
  displaY: flex;
  min-width: 180px;
}

.formdata {
  display: flex;
  padding: 10px;
  flex-direction: row;
  width: 70%;
}

.clue {
  display: flex;
  background-color: #096cc9;
  min-height: 50px;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 25px;
  font-weight: var(--font-weight-bold);
  color: var(--light-color);
  width: 50%;
}

.locationarrow {
  border-radius: 40px;
  width: 50px;
  height: 45px;
  padding-top: 15px;
  background-color: var(--primary-color-lighter)
}

.chapter {
  display: flex;
  flex-direction: column;
  background-color: #282c34;
  min-height: 50px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-weight: var(--font-weight-bold);
  color: var(--light-color);
}

.chapterTitle {
  width: 100%;
}

.instructions {
  display: flex;
  flex-direction: column;
  background-color: #282c34;
  min-height: 50px;
  align-items: center;
  justify-content: center;
  font-weight: var(--font-weight-bold);
  color: var(--secondary-color);
}

.premise {
  display: flex;
  flex-direction: column;
  min-height: 50px;
  width: 50%;
  margin: 10px;
  align-items: center;
  justify-content: center;
  color: var(--light-color);
  font-weight: var(--font-weight-regular);
}

.test {
  display: flex;
  flex-direction: column;
  background-color: #282c34;
  min-height: 50px;
  align-items: center;
  justify-content: center;
  font-weight: var(--font-weight-bold);
  color: var(--light-color);
}

#map {
  width: 100%;
  height: 340px;
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 50%;
  line-height: 1.5em;
  margin: auto;
  padding: 10px;
  background-color: var(--primary-color-highlight);
}

.map {
  display: flex;
  width: 95%;
  ;
  border-radius: 10px;
  ;
  flex-direction: row;
  background-color: var(--primary-color-highlight);
  margin: 5px;
  min-height: 50px;
  align-items: center;
  justify-content: center;
  font-weight: var(--font-weight-medium);
  color: var(--light-color);
}

.gamechapter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  line-height: 1em;
  margin: 0;
  padding: 2px;
  background-color: var(--primary-color-lighter);
  gap: 5px;
}

.left {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 100%;
}

.game {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.gameattributes {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 20px;
  gap: 2px;
}

.gameicon {
  border-radius: 10px;
  ;
}

.instructions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.progress {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  line-height: 1em;
  margin: 0;
}

.gameimage {
  max-height: 300px;
  max-width: 100%;
}

.score {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  line-height: 1em;
  margin: 0;
  padding: 2px;
  background-color: var(--primary-color-highlight);
}

.username {
  margin-left: 12px;
  font-weight: bold;
  font-style: italic;
  font-variant: small-caps;
}

.timer {
  font-size: 32px;
  color: var(--primary-text-color);
}

.timerNear {
  font-size: 32px;
  color: red;
}

.online {
  font-size: 32px;
  color: white;
  background-color: rgb(173, 31, 31);
}

.info {
  width: 100%;
  text-align: start;
  padding-left: 10px;
}

.error {
  display: flex;
}


/*@media (max-width: 768px) {*/
  .App-header {
    min-height: 20vh;
  }

  .info {
    width: 100%;
    text-align: start;
  }

  .container {
    width: 100%;
    align-items: stretch;
    padding: 5px;
  }

  .hcontainer {
    width: 100%;
  }

 
  
  .helpcontainer {
    width: 90%;
    text-align: start;
  }

  .right {
    padding: 10px;
  }

  .Small-header {
    min-height: 50vh;
  }

  .App-logo {
    height: 20vmin;
  }

  .App-header {
    font-size: calc(10px + 2vmin);
  }

  .Small-header {
    font-size: calc(1px + 2vmin);
  }

  .paragraph {
    font-size: 18px;
  }

  .form {
    padding: 5px;
  }

  .formgroup {
    gap: 5px;
    flex-direction: column;
    width: 100%;
    align-items: start;
    padding: 0;
    padding-top: 2px;
    margin-top: 10px;
  }

  .formlabel {
    width: 100%;
    padding: 5px;
  }

  .formdata {
    min-width: 70%;
    padding-top: 0;
  }

  .clue {
    font-size: 20px;
    width: 100%;
  }

  .hint {

    margin-top: 20px;
  }

  .chapter {
    font-size: 20px;
    width: 100%;
  }

  .chapterTitle {
    font-size: 20px;
    width: 100%;
  }

  .premise {
    font-size: 20px;
    width: 90%;
  }

  .test {
    font-size: 20px;
  }

  .map {
    font-size: 20px;
    flex-direction: column;
  }

  .instructions {
    font-size: 20px;
  }

  .gamechapter {
    font-size: 20px;
  }

  p {
    width: 90%;
  }

  .profile {
    width: 100%;
    padding: 1px;
    margin: 0;
  }


  .button {
    
  }
/*}*/