/* match2.css */


.matchcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.game-board {
    display: grid;
    grid-template-columns: repeat(4, 100px);
    grid-gap: 10px;
    margin-top: 20px;
}

.card {
    width: 100px;
    height: 100px;
    background-color: #fff;
    border: 2px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
    transition: transform 0.3s;
}

.card.flipped {
    background-color: #4caf50;
    color: #fff;
    transform: rotateY(180deg);
}

.card.matched {
    background-color: #2196f3;
    color: #fff;
    cursor: default;
}

.header {
    font-size: 32px;
    margin-bottom: 20px;
}

button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #45a049;
}


.match2-game {
    text-align: center;
    font-family: Arial, sans-serif;
}

.symbols-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    justify-content: center;
    margin: 20px auto;
    max-width: 400px;
}

.symbol {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    background-color: #f0f0f0;
    border: 2px solid #ccc;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.s0 {    
    background-image: url('s0.jpg');
    background-size: 80px 80px;
}
.s1 {    
    background-image: url('s1.jpg');
    background-size: 80px 80px;
}
.s2 {    
    background-image: url('s2.jpg');
    background-size: 80px 80px;
}
.s3 {    
    background-image: url('s3.jpg');
    background-size: 80px 80px;
}
.s4 {    
    background-image: url('s4.jpg');
    background-size: 80px 80px;
}
.s5 {    
    background-image: url('s5.jpg');
    background-size: 80px 80px;
}
.s6 {    
    background-image: url('s6.jpg');
    background-size: 80px 80px;
}
.s7 {    
    background-image: url('s7.jpg');
    background-size: 80px 80px;
}
.s8 {    
    background-image: url('s8.jpg');
    background-size: 80px 80px;
}

.symbol.visible {
    background-color: #e0e2ba;
}

.symbol:hover {
    background-color: #e0e0e0;
}
