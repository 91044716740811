.nextbutton {
    background-color: rgb(0, 0, 0);
    color: white;
    height: 50px;
    width: 50px;
    font-size: 32px;
    margin: 1px;
}

.partlabel {
    font-size: 24px;
    font-weight: bold;
    margin: 0 20px;
}

audio {
    width: 100%;
    margin-top: 20px;
    display: none;
}

.audioplayer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}